@import '../../styles/base_colors';

@mixin notification-card-theme($theme) {
  .notification-item {
    .item-card {
      background: white;
      &.disabled {
        background: rgba(black, 0.08);
      }
    }

    .notification-card {
      background-color: rgba($wd-primary, 0.15);
    }

    &.read {
      .notification-card {
        background-color: rgba(darken($wd-light-background, 5%), 90%);
      }
    }

    .notification-read {
      color: rgba(black, 0.4);
    }

    .notification-unread {
      color: rgba(black, 0.75);
    }
  }
}

@mixin notification-card-theme-dark($theme) {
  .notification-item {
    .item-card {
      &.disabled {
        background: rgba(white, 0.08);
      }
    }

    .notification-card {
      background-color: rgba($wd-accent, 0.5);
    }
    &.read {
      .notification-card {
        background-color: rgba($wd-dark-background, 70%);
      }
    }

    .notification-read {
      color: rgba(white, 0.2);
    }

    .notification-unread {
      color: rgba(white, 0.85);
    }

    .notification-date {
      &.notification-read {
        color: rgba(white, 0.4);
      }

      &.notification-unread {
        color: rgba(white, 0.65);
      }
    }
  }
}
