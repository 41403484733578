@use '@angular/material' as mat;
@import '../styles/base_colors';

@mixin shared-components-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .card-icon {
    &.background {
      background: $card-icon-background;
      mat-icon {
        color: $wd-gray;

        #Shape {
          fill: $wd-gray;
        }
      }
    }
  }

  .card-footer {
    background: rgba(black, 0.02);
  }

  .user-avatar {
    color: $wd-text;

    .profile-picture {
      color: $avatar-color;
      background: $avatar-background;
    }

    &.show-impersonation {
      .profile-picture {
        background-color: $avatar-impersonation-background;
      }
    }
  }

  .list-separator {
    background: rgba(black, 0.02);
    border-top: 1px solid rgba(black, 0.08);
    border-bottom: 1px solid rgba(black, 0.08);
  }

  .empty-list-placeholder {
    .background-icon {
      background: rgba(black, 0.08);
    }
  }

  // Dialogs
  .side-dialog-header {
    background: $wd-light-background;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }

  .side-dialog-footer {
    background: $wd-light-background;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  }

  .time-picker-container {
    .picker-form {
      background: $wd-light-background;

      mat-form-field {
        input {
          color: $wd-gray;
        }
      }

      .selector {
        mat-icon {
          font-size: 25px;
          cursor: pointer;
          color: $wd-gray;

          &.disabled {
            color: lighten($wd-gray, 30%);
            pointer-events: none;
          }
        }
      }
    }
  }
}
