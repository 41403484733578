@import '../../app/shared/styles/base_colors';

/**
 * Custom progress bar colors
 * Colors: yellow, gray
 **/

@mixin material-progress-bar-theme($theme) {
  .mat-mdc-progress-bar {
    &.mat-gray {
      /*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
      .mat-progress-bar-fill::after {
        background-color: $wd-gray !important;
      }
    }
    &.mat-yellow {
      /*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
      .mat-progress-bar-fill::after {
        background-color: $wd-yellow !important;
      }
    }
  }
}
