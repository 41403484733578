@import '../../../styles/base_colors';

.mat-expansion-panel {
  .header {
    cursor: pointer;
    margin: 0px 5px 0px 5px;
    background: transparent;

    mat-icon {
      font-size: 15px;
      line-height: 24px;
    }
  }

  margin: 0px 5px 0px 5px;
  padding: 0px;
  border: transparent;
  box-shadow: none !important;
  background: transparent;

  .mat-expansion-panel-header {
    height: 64px;
    margin: 0px;
    padding: 0px;
  }

  .mat-expansion-indicator {
    margin-right: 10px;
  }
}

@mixin comment-list-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  mat-panel-title > mat-icon,
  h3 {
    color: $wd-gray;
  }
}

@mixin comment-list-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  mat-panel-title > mat-icon,
  h3 {
    color: white;
  }
}
