@use 'sass:map';
@import '_base_colors';

.pointer {
  cursor: pointer;
}

$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 75, 80, 90, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

$wdcolors: (
  'primary': $wd-primary,
  'accent': $wd-accent,
  'warn': $wd-warn,
  'primary-light': $wd-primary-light,
  'white': $wd-white,
  'black': $wd-black,
  'blue': $wd-blue,
  'green': $wd-green,
  'yellow': $wd-yellow,
  'red': $wd-red,
  'background': $wd-background,
  'blue-light': $wd-blue-light,
  'purple': $wd-purple,
  'purple-light': $wd-purple-light,
  'dark-gray': #555,
  'orange': $wd-orange,
);

$alphas: (
  '10': 0.1,
  '20': 0.2,
  '30': 0.3,
  '40': 0.4,
  '50': 0.5,
  '60': 0.6,
  '70': 0.7,
  '80': 0.8,
  '90': 0.9,
  '100': 1,
);

@each $n, $c in $wdcolors {
  .wd-#{$n} {
    color: #{$c} !important;
  }

  .bg-wd-#{$n} {
    background-color: #{$c} !important;
  }
}

@each $i, $t in $alphas {
  @each $n, $c in $wdcolors {
    .wd-#{$n}-#{$i} {
      color: rgba($c, $t) !important;
    }

    .bg-wd-#{$n}-#{$i} {
      background-color: rgba($c, $t) !important;
    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.wrap-break-word {
  overflow-wrap: break-word;
}

mat-icon.lg,
.material-symbols-outlined.md {
  width: 34px !important;
  height: 34px !important;
  font-size: 34px !important;
  &.centered {
    height: inherit;
  }
}

mat-icon.md,
.material-symbols-outlined.md {
  width: 18px !important;
  height: 18px !important;
  font-size: 18px !important;
}

mat-icon.sm,
.material-symbols-outlined.sm {
  font-size: 16px !important;
  width: 20px !important;
  height: 16px !important;
}

mat-icon.xs,
.material-symbols-outlined.xs {
  width: 14px !important;
  height: 14px !important;
  font-size: 14px !important;
}

.decision-detail-dialog .mat-mdc-dialog-container {
  padding: 0 !important;
}

.pre-line {
  white-space: pre-line;
}

.inline-block {
  display: inline-block;
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  font-style: italic;
}

[contenteditable='true']:focus {
  outline: none;
}

.ready-to-decide-button {
  background-color: $wd-yellow !important;
  color: white !important;
}

.mat-mdc-raised-button.mat-prepare:not([disabled]),
.mat-mdc-unelevated-button.mat-prepare:not([disabled]),
.mat-mdc-fab.mat-prepare:not([disabled]),
.mat-mdc-mini-fab.prepare:not([disabled]) {
  color: #fff !important;
  background-color: $wd-red !important;
}

.mat-mdc-raised-button.mat-ready-to-decide:not([disabled]),
.mat-mdc-unelevated-button.mat-ready-to-decide:not([disabled]),
.mat-mdc-fab.mat-ready-to-decide:not([disabled]),
.mat-mdc-mini-fab.ready-to-decide:not([disabled]) {
  color: #fff !important;
  background-color: $wd-yellow !important;
}

.mat-mdc-raised-button.mat-decice:not([disabled]),
.mat-mdc-unelevated-button.mat-decide:not([disabled]),
.mat-mdc-fab.mat-decide:not([disabled]),
.mat-mdc-mini-fab.decide:not([disabled]) {
  color: #fff !important;
  background-color: $wd-primary !important;
}

.mat-mdc-raised-button.mat-evaluate:not([disabled]),
.mat-mdc-unelevated-button.mat-evaluate:not([disabled]),
.mat-mdc-fab.mat-evaluate:not([disabled]),
.mat-mdc-mini-fab.evaluate:not([disabled]) {
  color: #fff !important;
  background-color: $wd-primary !important;
}

::ng-deep .mat-mdc-standard-chip:focus::after {
  opacity: 0 !important;
}

::ng-deep .mat-mdc-standard-chip:hover::after {
  opacity: 0 !important;
}

.mat-mdc-standard-chip::after {
  display: none;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.mat-mdc-tooltip.navbar-tooltip {
  font-size: 15px;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-top: 3px;
}

.display-chip {
  font-size: 13px;
  font-weight: 300;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 6px 10px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 13px;
  height: 1px;
  margin-bottom: 3px;
}

.manage-container {
  .column-content {
    .card-wrapper:last-child {
      wd-card {
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
        mat-card.item-card {
          margin-bottom: 80px;
        }
      }
    }
  }
}

.no-focus {
  &:focus {
    outline: none !important;
  }
}

.mat-mdc-progress-bar {
  border-radius: 2px;
}

.mat-mdc-card,
.mat-expansion-panel-header {
  font-family: 'Lato', 'Roboto', 'Helvetica Neue', sans-serif;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.item-card {
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;

  .mat-mdc-card-header {
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    ::ng-deep .mat-card-header-text {
      margin: 0;
    }

    .mat-mdc-card-title {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 16px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  mat-card-actions {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .mat-icon {
      transform: scale(0.85);
    }
  }
}

.no-padding-dialog {
  mdc-dialog__container.mat-mdc-dialog-container {
    padding: 0;
    border-radius: 0px;
  }
}

.icon-white svg path {
  fill: white;
  stroke: white;
}

.icon-blue svg path {
  fill: $wd-primary;
  stroke: $wd-primary;
}

.icon-gray svg path {
  fill: $wd-gray;
  stroke: $wd-gray;
}

.icon-white svg path {
  fill: white;
  stroke: white;
}

.icon-purple-light svg path {
  fill: $wd-purple-light;
  stroke: $wd-purple-light;
}

.add-button-container {
  position: fixed;
  right: 40px;
  bottom: 40px;
}

.side-dialog-content {
  padding: 10px 20px 0px;
  min-height: calc(100vh - 130px);

  &.no-padding {
    padding: 0;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox.mat-checkbox-disabled {
  label {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    span.mat-checkbox-inner-container {
      span.mdc-checkbox__checkmark {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.bottom-info-banner {
  position: fixed;
  z-index: 99;
  background: darken($wd_gray, 10%);
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 20px;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .bottom-info-banner {
    padding: 10px;
  }
}

.number-of {
  margin-left: 4px;
  margin-right: 4px;
}

@media screen and (max-width: 960px) {
  .r6o-editor {
    max-width: 350px;

    .r6o-widget {
      .r6o-icon {
        min-width: 32px;
        min-height: 32px;
        border-radius: 5px;

        svg {
          padding-top: 7px;
          padding-bottom: 7px;
          min-width: 18px;
          min-height: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .r6o-editor {
    max-width: 300px;

    .r6o-widget {
      .r6o-icon {
        min-width: 32px;
        min-height: 32px;
        border-radius: 5px;

        svg {
          padding-top: 7px;
          padding-bottom: 7px;
          min-width: 18px;
          min-height: 18px;
        }
      }
    }
  }
}

input[type='time']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
  background: none;
}

// FIXME - // https: //github.com/angular/components/issues/24614
/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version. */
.mat-tooltip-hide {
  display: none !important;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mdc-fab-icon-color: #fff !important;
  --mat-mdc-fab-color: #fff !important;
  white-space: nowrap !important;
}

.mat-mdc-form-field {
  margin: 4px 0 !important;
}

.mdc-button {
  line-height: 36px!important;
}

.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  white-space: nowrap !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 16px !important;

  .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
    white-space: nowrap;
  }
}

.mat-mdc-button.mat-mdc-button-base {
  white-space: nowrap !important;
  height: inherit !important;
}

.mdc-dialog__actions {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.mat-mdc-card-title {
  --mat-card-title-text-font: 'Lato' !important;
}

.mat-mdc-menu-content {
  --mat-menu-item-label-text-size: 14px !important;
}

.mat-mdc-form-field-input-control {
  font-size: 14px !important;
}

.mdc-checkbox__checkmark {
  color: #fff !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-handle-color: $wd-primary;
  --mdc-switch-selected-pressed-handle-color: $wd-primary;
  --mdc-switch-selected-pressed-state-layer-color: $wd-primary;
  --mdc-switch-selected-hover-state-layer-color: $wd-primary;
  --mdc-switch-selected-hover-handle-color: $wd-primary;
  --mdc-switch-selected-focus-state-layer-color: $wd-primary;
  --mdc-switch-selected-focus-handle-color: $wd-primary;
  --mdc-switch-selected-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
}

.mdc-evolution-chip__text-label {
  display: flex;
  align-items: center;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 5px 0 10px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  .mat-mdc-form-field-hint-wrapper {
    position: static !important;
    padding: 5px 16px;
  }

  &.mat-mdc-form-field-bottom-align {
    &:before {
      display: none !important;
    }
  }
}

// Localize styles for mdc-checkbox not globally because it breaks todos checkbox
/*.mat-mdc-checkbox{
  --mdc-checkbox-state-layer-size: 18px;
}

.mat-mdc-checkbox-touch-target {
  height: 20px !important;
  width: 20px !important;
}*/

.mdc-radio {
  --mdc-radio-state-layer-size: 25px;
}

.mat-mdc-radio-touch-target {
  height: 25px !important;
  width: 25px !important;
}
