@use '@angular/material' as mat;
@import '../../styles/base_colors';

@mixin board-chip-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-mdc-chip.group-filter-chip {
    background-color: darken($wd-light-background, 1%);
    border: 1px solid lighten(lightgray, 10%);

    .counter {
      color: lighten($foreground, 30%);
    }

    &.selected {
      background-color: mat.get-color-from-palette($primary) !important;
      border: 1px solid mat.get-color-from-palette($primary) !important;
      color: white !important;

      .counter {
        color: rgba(white, 90%);
      }
    }
  }
}

@mixin board-chip-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-mdc-chip.group-filter-chip {
    background-color: rgba(black, 0.05);
    border: 1px solid darken(darkgray, 35%);

    .counter {
      color: lighten(#000, 75%);
    }

    &.mat-mdc-chip.selected {
      background-color: mat.get-color-from-palette($primary) !important;
      border: 1px solid mat.get-color-from-palette($primary) !important;
      color: white !important;

      .counter {
        color: rgba(white, 90%);
      }
    }
  }
}
