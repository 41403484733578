// $primary: #2e7d9e;
// $primary: #003B54;
$primary: #005377;
// $accent: #35b9c6;
// $accent: #b75b86;
// $accent: #88BDF2;
// $accent: #A13D63;
$accent: #36B9C6;
$warn: #f2506b;
$green: #35c69b;
$blue: #2993C0;
$purple: #9c72c6;
$yellow: #ffa000;
$orange: #e67e22;
$gray: #62747f;
$light-background: #ffffff;
$dark-background: #303030;
$gray-background: #f0f0f0;
$white: #ffffff;
$text: #3d474d;

// Navbar
// $navbar-background: linear-gradient(90deg, darken($primary, 5%) 0%, darken($accent, 5%) 100%);
// #36BAC7
// #9E2E7D purple
// #B9C635 yellowgreen
// #36BAC7 teal
// $navbar-background: linear-gradient(90deg, #286d8a 0%, #286d8a 27%, #30a6b2 66%, #b75b86 100%);
// $navbar-background: linear-gradient(90deg, darken($primary, 5%), $primary 75%, $accent 100%);
$navbar-background: linear-gradient(90deg, darken($primary, 5%) 25%, $primary 50%, darken($blue, 10%) 75%, $blue 100%);
// $navbar-background: red;
$navbar-item-color: $white;
$navbar-hover-color: lighten($accent, 8%);
$navbar-active-color: lighten($accent, 8%);
// $navbar-hover-color: lighten(#B9C635, 5%);
// $navbar-active-color: lighten(#B9C635, 5%);
// $navbar-impersonation-background: linear-gradient(90deg, darken($primary, 5%) 0%, darken($accent, 5%) 80%, darken($orange, 5%) 100%);
$navbar-impersonation-background: linear-gradient(90deg,  darken($primary, 5%) 25%, $primary 75%, darken($orange, 5%) 100%);

// Avatar and Profile picture
$avatar-background: $accent;
$avatar-color: $white;
$avatar-impersonation-background: $orange;

// Cards
$card-icon-background: transparent;

// Chips
$chip-color: $primary;
$chip-background: rgba($primary, 15%);

// Login page
$login-background-color: linear-gradient(45deg, darken($primary, 5%) 0%, lighten($primary, 5%) 100%);
$login-footer-link-color: $white;
