@use '@angular/material' as mat;

@mixin sidenav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-mdc-list-base .mat-mdc-list-item {
    &.active {
      --mdc-list-list-item-label-text-color: white;
      background-color: mat.get-color-from-palette($primary);
      color: white;
    }
  }
}
