@import '../../../shared/styles/base_colors';
@import '../../../shared/styles/color_palette';

@mixin meeting-detail-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .meeting-subheader-container,
  .meeting-header-container {
    background: rgba($wd-light-background, 0.95);
  }
}

@mixin meeting-detail-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .meeting-subheader-container,
  .meeting-header-container {
    background: rgba($wd-dark-background, 0.95);
  }
}

