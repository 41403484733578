@import '../../shared/styles/base_colors';
@import '../../shared/styles/color_palette.scss';

@mixin connect-users-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .table-header {
    color: black;
  }
}

@mixin connect-users-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .table-header {
    color: white;
  }
}
