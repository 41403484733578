@import '../../../shared/styles/base_colors';
@import '../../../shared/styles/color_palette';

@mixin proposals-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .breadcrumb-bar {
    background: $wd-light-background;
    opacity: 1;
  }

  .wd-label {
    color: rgba(0, 0, 0, 0.6);
  }

  .proposal-header-container {
    background: rgba($wd-light-background, 0.95) !important;
  }
}

@mixin proposals-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .breadcrumb-bar {
    background: $wd-dark-background;
  }

  .wd-label {
    color: rgba(white, 0.6);
  }

  .proposal-header-container {
    background: rgba($wd-dark-background, 0.95) !important;
  }
}
