@use '@angular/material' as mat;
@import '../../../styles/base_colors';

@mixin decision-dialog-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .decision-dialog {
    .left-wrapper {
      background: darken($wd-light-background, 5%);
    }

    .bottom-bar-wrapper {
      background: linear-gradient(0deg, rgba($wd-light-background, 100%) 50%, rgba($wd-light-background, 10%) 100%);
    }
  }
}

@mixin decision-dialog-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  .decision-dialog {
    .left-wrapper {
      background: light($wd-dark-background, 5%);
    }

    .bottom-bar-wrapper {
      background: linear-gradient(0deg, rgba($wd-dark-background, 100%) 50%, rgba($wd-dark-background, 10%) 100%);
    }
  }
}
