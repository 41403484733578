.mat-h1,
.mat-headline-5,
.mat-typography h1 {
  font: 400 24px/32px Lato, Roboto, 'Helvetica Neue', sans-serif;
}

.mat-h2,
.mat-headline-5,
.mat-typography h2 {
  font: 400 20px / 32px Lato, Roboto, 'Helvetica Neue', sans-serif;
}

.mat-h3,
.mat-subheading2,
.mat-typography h3 {
  font: 600 17px/28px Lato, Roboto, 'Helvetica Neue', sans-serif;
}

// Material components
.mat-mdc-form-field {
  font-family: Lato, Roboto, 'Helvetica Neue', sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px;
}

