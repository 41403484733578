@use '@angular/material' as mat;
@import '../../../../shared/styles/base_colors';
@import '../../../../shared/styles/color_palette';

@mixin meeting-minutes-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .minutes-content {
    .toastui-editor-toolbar {
      background-color: rgba($wd-light-background, 0.95);
    }
  }

  .agenda-item-container {
    color: rgba(0, 0, 0, 0.34);
    background: rgba(black, 0.02);
    border-bottom: 1px solid rgba(black, 0.12);
    border-left: 4px solid transparent;

    &.selectable {
      background: $wd-light-background;
      color: rgba(0, 0, 0, 0.87);
      &:hover {
        background: rgba(mat.get-color-from-palette($primary), 0.05);
      }
      &.active {
        background: rgba(mat.get-color-from-palette($primary), 0.05);
        border-left: 4px solid mat.get-color-from-palette($primary);
      }
    }
  }

  .agenda-item-comment-box {
    background: $wd-light-background;
  }

  .right-container {
    background: $wd-light-background;
  }

  .minutes-container {
    .minutes-content {
      padding: 10px 40px;
    }

    h4.headline {
      color: lighten($foreground, 35%);
      font-size: 14px;
    }
  }

  .sticky-bottom-bar {
    background: rgba(darken($wd-light-background, 5%), 90%);
  }

  .collapsable-description-container {
    &.collapsed {
      &::after {
        background: linear-gradient(0deg, #fcfcfc 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}

@mixin meeting-minutes-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .minutes-content {
    .toastui-editor-toolbar {
      background-color: rgba($wd-dark-background, 0.95);
    }
  }

  .agenda-item-container {
    color: lighten(#000, 70%);
    background: rgba(white, 0.1);
    border-bottom: 1px solid lighten(black, 30%);

    &.selectable {
      background: $wd-dark-background;
      color: white;
      &:hover {
        background: rgba(mat.get-color-from-palette($primary), 0.1);
      }
      &.active {
        background: rgba(mat.get-color-from-palette($primary), 0.2);
        border-left: 4px solid mat.get-color-from-palette($primary);
      }
    }
  }

  .agenda-item-comment-box {
    background: $wd-dark-background;
  }

  .right-container {
    background: $wd-dark-background;
  }

  .minutes-container {
    .minutes-content {
      padding: 10px 40px;
    }

    h4.headline {
      color: lighten(#000, 75%);
      font-size: 14px;
    }
  }

  .sticky-bottom-bar {
    background: rgba(lighten($wd-dark-background, 5%), 90%);
  }

  .collapsable-description-container {
    &.collapsed {
      &::after {
        background: linear-gradient(0deg, $wd-dark-background 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }
}
