@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;

mat.$theme-ignore-duplication-warnings: true;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@include cdk.text-field();

@import './app/shared/styles/base_colors';
@import './app/shared/styles/color_palette';
@import './app/shared/styles/custom';

@import './themes/library-styles';
@import './themes/wedecide-light-theme';
@import './themes/wedecide-dark-theme';
@import './themes/material-custom/buttons';
@import './themes/material-custom/progress-bar';
@import './themes/material-custom/card';
@import './themes/material-custom/expansion-panel';
@import './themes/material-custom/typography';

@import './app/auth/auth.component.scss-theme';
@import './app/circular-resolutions/circular-resolutions/circular-resolutions.component.scss-theme';
@import './app/circular-resolutions/cr-list/cr-list.component.scss-theme';
@import './app/dashboard/dashboard.component.scss-theme';
@import './app/decisions/decision-list/decisions-list.component.scss-theme';
@import './app/decisions/report-item/report-item.component.scss-theme';
@import './app/meetings/decider-view/decider-view.component.scss-theme';
@import './app/meetings/meeting-manager/meeting-detail/meeting-detail.component.scss-theme';
@import './app/meetings/meeting-manager/meeting-manager/meeting-manager.component.scss-theme';
@import './app/meetings/meeting-manager/meeting-list/meeting-list.component.scss-theme';
@import './app/meetings/meeting-manager/overlay/presentation-viewer/presentation-viewer.component.scss-theme';
@import './app/meetings/meeting-manager/meeting-detail/minutes/minutes.component.scss-theme';
@import './app/meetings/proposals/proposals/proposals.component.scss-theme';
@import './app/meetings/proposals/proposal-list/proposal-list.component.scss-theme';
@import './app/shared/components/board-select-chip/board-select-chip.component.scss-theme';
@import './app/shared/modules/signing-pad/signing-pad.component.scss-theme';
@import './app/shared/modules/decision-dialog/decision-dialog/decision-dialog.component.scss-theme';
@import './app/shared/components/notification-card/notification-card.component.scss-theme';
@import './app/shared/components/light-components.scss-theme.scss';
@import './app/shared/components/dark-components.scss-theme.scss';
@import './app/shared/modules/comments/comments-list/comments-list.component.scss';
@import './app/shared/layout/navbar/navbar.component.scss-theme.scss';
@import './app/shared/layout/sidenav/sidenav.component.scss-theme';
@import './app/settings/connect-users/connect-users.component.scss-theme.scss';
@import './themes/live-voting/live-voting.scss-theme';

@mixin list-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .column-nav {
    .bullet {
      &.active {
        &::before {
          box-shadow: inset 1px 1px 10px 0px rgba(white, 0.4);
          border: 2px solid rgba(white, 0.4);
        }
      }
    }
  }
}

@mixin list-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .column-nav {
    .bullet {
      &.active {
        &::before {
          box-shadow: inset 1px 1px 10px 0px rgba(black, 0.4);
          border: 2px solid rgba(black, 0.4);
        }
      }
    }
  }
}

@mixin component-themes($theme) {
  // add general component mixins
  @include mat.sidenav-theme($theme);
  @include mat.button-theme($theme);
  @include mat.progress-bar-theme($theme);

  --mdc-filled-text-field-label-text-size: 14px !important;
  --mdc-outlined-text-field-label-text-size: 14px !important;
  --mat-form-field-container-text-size: 14px !important;
  --mat-form-field-outlined-label-text-populated-size: 14px !important;
  --mat-select-trigger-text-size: 14px !important;
  --mat-option-label-text-size: 14px !important;
  --mat-form-field-subscript-text-line-height: 14px !important;
  --mdc-list-list-item-one-line-container-height: 60px;
  --mat-tab-header-divider-height: 1px;
  --mat-tab-header-divider-color: rgba(0,0,0,.12);
  --mdc-plain-tooltip-supporting-text-size: 10px;

  .mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: white;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  .mat-mdc-slide-toggle {
    --mdc-switch-selected-handle-color: $wd-primary;
    --mdc-switch-selected-pressed-handle-color: $wd-primary;
    --mdc-switch-selected-pressed-state-layer-color: $wd-primary;
    --mdc-switch-selected-hover-state-layer-color: $wd-primary;
    --mdc-switch-selected-hover-handle-color: $wd-primary;
    --mdc-switch-selected-focus-state-layer-color: $wd-primary;
    --mdc-switch-selected-focus-handle-color: $wd-primary;
    --mdc-switch-selected-track-color: #e0e0e0;
    --mdc-switch-selected-pressed-track-color: #e0e0e0;
    --mdc-switch-selected-hover-track-color: #e0e0e0;
    --mdc-switch-selected-focus-track-color: #e0e0e0;
  }
  /* ... */
}

@mixin light-component-themes($theme) {
  // add light component mixins
  @include navbar-theme($theme);
  @include sidenav-theme($theme);
  @include list-component-theme($theme);
  @include auth-component-theme($theme);
  @include manage-meeting-manager-component-theme($theme);
  @include meeting-detail-component-theme($theme);
  // @include manage-meeting-list-component-theme($theme);
  @include decider-view-component-theme($theme);
  @include meeting-presentation-view-component-theme($theme);
  @include meeting-minutes-theme($theme);
  @include board-chip-component-theme($theme);
  @include circular-resolution-component-theme($theme);
  @include circular-resolution-list-component-theme($theme);
  @include dashboard-component-theme($theme);
  @include decision-list-component-theme($theme);
  @include meeting-decision-report-item-theme($theme);
  @include signing-pad-component-theme($theme);
  @include decision-dialog-component-theme($theme);
  @include notification-card-theme($theme);
  @include shared-components-theme($theme);
  @include proposals-component-theme($theme);
  @include proposal-list-component-theme($theme);
  @include connect-users-component-theme($theme);
  @include comment-list-component-theme($theme);
  @include material-button-theme($theme);
  @include live-voting-theme($theme);
  /* ... */

  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}

@mixin dark-component-themes($theme) {
  // add dark component mixins
  @include navbar-theme-dark($theme);
  @include sidenav-theme($theme);
  @include list-component-theme-dark($theme);
  @include auth-component-theme-dark($theme);
  @include manage-meeting-manager-component-theme-dark($theme);
  @include meeting-detail-component-theme-dark($theme);
  @include manage-meeting-list-component-theme-dark($theme);
  @include decider-view-component-theme-dark($theme);
  @include meeting-presentation-view-component-theme-dark($theme);
  @include meeting-minutes-theme-dark($theme);
  @include board-chip-component-theme-dark($theme);
  @include circular-resolution-component-theme-dark($theme);
  @include circular-resolution-list-component-theme-dark($theme);
  @include dashboard-component-theme-dark($theme);
  @include decision-list-component-theme-dark($theme);
  @include meeting-decision-report-item-theme-dark($theme);
  @include signing-pad-component-theme-dark($theme);
  @include decision-dialog-component-theme-dark($theme);
  @include notification-card-theme-dark($theme);
  @include shared-components-theme-dark($theme);
  @include proposals-component-theme-dark($theme);
  @include proposal-list-component-theme-dark($theme);
  @include connect-users-component-theme-dark($theme);
  @include comment-list-component-theme-dark($theme);
  @include material-button-theme($theme);
  @include live-voting-theme-dark($theme);
  /* ... */
  .mat-mdc-snack-bar-action {
    color: #424242DE !important;
  }
}

.wedecide-light-theme {
  @include mat.all-component-themes($wedecide-light-theme);
  @include component-themes($wedecide-light-theme);
  @include light-component-themes($wedecide-dark-theme);
}

.wedecide-dark-theme {
  @include mat.all-component-themes($wedecide-dark-theme);
  @include component-themes($wedecide-dark-theme);
  @include dark-component-themes($wedecide-dark-theme);
}

.bg-gray {
  background-color: $wd-gray !important;
}

.mat-primary {
  &.mat-mdc-button-base {
    --mat-fab-foreground-color: #fff !important;
    --mdc-filled-button-label-text-color: #fff !important;
    --mdc-protected-button-label-text-color: #fff !important;
  }

  &.mat-mdc-fab,
  &.mat-mdc-mini-fab {
    --mdc-fab-icon-color: #fff !important;
    --mat-mdc-fab-color: #fff !important;
    white-space: nowrap !important;
  }
}

.mat-mdc-form-field {
  margin: 4px 0 !important;
}

.mdc-button {
  line-height: 36px!important;
}

.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  white-space: nowrap !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 16px !important;

  .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
    white-space: nowrap;
  }
}

.mat-mdc-button.mat-mdc-button-base {
  white-space: nowrap !important;
}

.mdc-dialog__actions {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.mat-mdc-card-title {
  --mat-card-title-text-font: 'Lato' !important;
}

.mat-mdc-form-field-input-control {
  font-size: 14px !important;
}

