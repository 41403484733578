@import '../../app/shared/styles/base_colors';

/**
 * Custom material button colors
 * Colors: yellow, gray
 **/
@mixin material-button-theme($theme) {
  .mat-mdc-button,
  .mat-mdc-outlined-button {
    &.mat-yellow {
      color: $wd-yellow;
    }
    &.mat-gray {
      color: $wd-gray;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-purple {
      color: $wd-purple;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-blue-light {
      color: $wd-blue-light;
    }
  }

  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-green {
      color: white;
      background-color: $wd-green;
    }
    &.mat-red {
      color: white;
      background-color: $wd-red;
    }

    &.mat-yellow {
      color: white;
      background-color: $wd-yellow;
    }
    &.mat-gray {
      color: white;
      background-color: $wd-gray;
    }
    &.mat-purple-light {
      color: white;
      background-color: $wd-purple-light;
    }
    &.mat-purple {
      color: white;
      background-color: $wd-purple;
    }
    &.mat-purple-light {
      color: white;
      background-color: $wd-purple-light;
    }
    &.mat-blue-light {
      color: white;
      background-color: $wd-blue-light;
    }
  }

  .mat-mdc-icon-button {
    &.mat-yellow {
      color: $wd-yellow;
    }
    &.mat-gray {
      color: $wd-gray;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-purple {
      color: $wd-purple;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-blue-light {
      color: $wd-blue-light;
    }
  }
}

.mat-mdc-unelevated-button,
.mat-mdc-outlined-button,
.mat-mdc-raised-button {
  &.large {
    padding: 8px 16px;
    font-size: 15px;
  }

  &.rounded {
    border-radius: 40px !important;
  }

  &.fab {
    z-index: 1000;
  }
}
