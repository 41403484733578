@tailwind base;
@tailwind components;
@tailwind utilities;

@import './app/shared/styles/base_colors';

/* You can add global styles to this file, and also import other style files */

/* THEMES */
/* See more about theming here https://material.angular.io/guide/theming */

/* Built-in Material Themes
/* Use primary/accent colors in material components by defining color="primary" or color="accent" */
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import '@angular/material/prebuilt-themes/pink-bluegrey.css';
// @import '@angular/material/prebuilt-themes/purple-green.css';

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html,
body,
wd-root,
.app-frame {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  overflow-x: hidden;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

/* workaround for weird tailwind defaults */
img,
video {
  max-width: none;
}

body,
.toastui-editor-contents {
  margin: 0;
  padding: 0;
  // font-family: 'Roboto';
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;

  ins.change-detect {
    background: rgba($wd-green, 0.2);
  }

  del.change-detect {
    background: rgba($wd-red, 0.2);
  }
}

.mat-body,
.mat-body-2,
.mat-typography {
  font:
    400 14px/20px 'Lato',
    'Roboto',
    'Helvetica Neue',
    sans-serif;
  letter-spacing: normal;
}

main {
  position: relative;
}

.centerBox {
  // margin: 40px auto;
  text-align: center;
}

.auth-layout {
  min-height: 100vh;
}

.page-header {
  h1 {
    margin: 0;
    font-weight: normal !important;
  }
}

.left-panel {
  float: left;
  width: 250px;
}
.right-panel {
  margin-left: 260px;
}
.hit-name {
  margin-bottom: 0.5em;
}
.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.change-detector-span {
  p {
    margin: 0;
    padding: 0;
  }
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.mat-mdc-chip {
  // disable text highlighting
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.mat-mdc-card {
  font-family: 'Lato' !important;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid $wd-primary !important;
  }

  &.no-hover {
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.move-down {
  position: relative;
  top: 3px;
}

.firebase-emulator-warning {
  display: none !important;
}

//
.duedate-chip {
  padding: 3px 5px;
  background-color: rgba($wd-primary, 20%);
  border-radius: 2px;
  color: $wd-primary;
  font-size: 13px;
  font-weight: 500;
  margin-right: 5px;

  &.orange {
    background-color: rgba($wd-orange, 20%);
    color: $wd-orange;
  }

  &.red {
    background-color: rgba($wd-red, 20%);
    color: $wd-red;
  }
}

// This fixes https://github.com//issues/4609
// force bottom bar to bottom in mat dialog
.fixed-bottom-bar {
  height: 0px;
  mdc-dialog__container {
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }
  }
}

.mat-mdc-optgroup-label {
  color: $wd-primary !important;
}

.icon-button-hover:hover {
  background: rgba(black, 0.08);
}

/*
Fixes issue with buttons which open dialogs where was implemented autofocus on inputs.
Buttons became darker 'cause opacity was like 0.7 ( material specifies this value )
*/
.cdk-program-focused {
  .mat-mdc-button-persistent-ripple {
    opacity: 0 !important;
  }
}


.toastui-editor-main {
  padding-left: 8px;
}

// Keyframes
@keyframes spinner_bottom_animation {
  0% {
    opacity: 0.8;
  }
  16% {
    opacity: 1;
  }
  84% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes spinner_middle_animation {
  0% {
    opacity: 0.1;
  }
  16% {
    opacity: 0.1;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  84% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes spinner_top_animation {
  0% {
    opacity: 0.1;
  }
  33% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes fa-bump {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fa-bump {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.dx-menu-base, .dx-widget {
  font-family: 'Lato' !important;
}