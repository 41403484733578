@use '@angular/material' as mat;
@import '../styles/base_colors';

@mixin shared-components-theme-dark($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  .card-icon {
    .mat-icon {
      color: white;
    }

    &.background {
      background: adjust-color(mat.get-color-from-palette($primary), $lightness: 10%, $saturation: -30%);

      .mat-icon {
        color: mat.get-color-from-palette($foreground);

        #Shape {
          fill: white;
        }
      }
    }
  }

  .date-and-time-line {
    color: white;
  }

  .card-footer {
    background: rgba(white, 0.05);
  }

  .user-avatar {
    color: white;
    .profile-picture {
      background-color: rgba(black, 0.5);
    }
  }

  .tab-filter-option {
    color: white;
  }

  .report-gauge-chart {
    color: white;
  }

  .list-separator {
    background: rgba(white, 0.02);
    border-top: 1px solid rgba(white, 0.08);
    border-bottom: 1px solid rgba(white, 0.08);
    color: rgba(white, 0.9);
  }

  .empty-list-placeholder {
    .background-icon {
      background: rgba(white, 0.08);
    }
  }

  // Dialogs
  .side-dialog-header {
    background: $wd-dark-background;
    color: white;
    border-bottom: 1px solid rgba(white, 0.12);
  }

  .side-dialog-footer {
    background: $wd-dark-background;
    color: white;
    border-top: 1px solid rgba(white, 0.12);
  }

  .time-picker-container {
    .picker-form {
      background: $wd-dark-background;

      mat-form-field {
        input {
          color: $wd-white;
        }
      }

      .selector {
        mat-icon {
          font-size: 25px;
          cursor: pointer;
          color: $wd-white;

          &.disabled {
            color: $wd-gray;
            pointer-events: none;
          }
        }
      }
    }
  }
}
