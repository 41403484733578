@import '../../styles/base_colors';

@mixin signing-pad-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .canvas-bg {
    background: rgba(black, 0.05);
  }
}

@mixin signing-pad-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .canvas-bg {
    background: rgba(white, 0.05);
  }
}
