@import '../../shared/styles/base_colors';
@import '../../shared/styles/color_palette';

@mixin circular-resolution-list-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .circular-resolution-header-container {
    background: rgba($wd-light-background, 0.95);
  }
}

@mixin circular-resolution-list-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .circular-resolution-header-container {
    background: rgba($wd-dark-background, 0.95);
  }
}
