@import '../../app/shared/styles/base_colors';

$section-light-bg: #f3f3f3;
$section-dark-bg: #2b2b2b;

@mixin live-voting-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .live-voting-primary-bg {
    background: $wd-primary;
  }

  .live-voting-container-bg {
    background: rgb(66, 66, 66, 0.85);
  }

  .live-voting-text-color {
    color: $wd-white;
  }

  .live-voting-pulsating-color {
    border-color: $wd-primary !important;
  }

  live-voting-pulsating-bg {
    background: $wd-primary;
  }

  .live-voting-bottom-border-color {
    border-color: $wd-white !important;
  }

  .voting-section-bg {
    background: $section-light-bg;
  }

  .active-live-voting-item-bg {
    background: $wd-white;
  }
}

@mixin live-voting-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .live-voting-primary-bg {
    background: $wd-primary;
  }

  .live-voting-container-bg {
    background: #222222;
  }

  .live-voting-text-color {
    color: $wd-white;
  }

  .live-voting-pulsating-color {
    border-color: $wd-primary !important;
  }

  live-voting-pulsating-bg {
    background: $wd-primary;
  }

  .live-voting-bottom-border-color {
    border-color: $wd-white !important;
  }

  .active-live-voting-item-bg {
    background: $section-dark-bg
  }

  .voting-container-bg {
    background: $section-dark-bg;
  }
}
